import React, {useEffect} from 'react';
import {captureException} from '@sentry/react';
import Header from "../Header";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getDefaultOffersBoxStyles} from "../../OffersScreen/styles/box";

const NotFound = () => {

  const useStyles = makeStyles(theme => getDefaultOffersBoxStyles(theme))
  const classes = useStyles()

  useEffect(() => {
    const error = new Error("Please use BrandConnections widget in popup context only!")
    console.error(error)
    captureException(error)
  }, [])

  return (
    <Box p={{xs: 2, md: 5}}
         boxShadow={{xs: 0, sm: 0, md: 6}}
         className={classes.consentBox}
         style={{minHeight: '300px'}}>
      <Header/>
      <Typography className={classes.consentTopTitle}>
        Wrong Usage Detected
      </Typography>
      <Typography className={classes.consentSubTitle}>
        Please use BrandConnections widget in popup context only!
      </Typography>
    </Box>);
};

export default NotFound;