import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
const pkg = require('../package.json');

const envs = ['local', 'dev', 'stage', 'prod'];
let sentryEnv = 'dev';
if (envs.indexOf(process.env.REACT_APP_ENV) !== -1) {
  sentryEnv = process.env.REACT_APP_ENV;
}

// Prepare Sentry additional variables.
let APP_RELEASE = 'develop';
if (process.env.REACT_APP_GITHUB_SHA) {
  // Set App release.
  APP_RELEASE = `${sentryEnv}-${process.env.REACT_APP_GITHUB_SHA}`;
  // Get prod tag name.
  if (process.env.REACT_APP_ENV === 'prod') {
    APP_RELEASE = process.env.REACT_APP_GITHUB_REF;
  }
}

Sentry.init({
  dsn: "https://98106b07b8925c4186dda2296d303219@o287038.ingest.us.sentry.io/4508477843505152",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  environment: sentryEnv,
  tracesSampleRate: sentryEnv === 'prod' ? 0.1 : 1.0,
  release: `${pkg.name}-${APP_RELEASE}`,
});

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
