import React, {useEffect, useState} from 'react';
import {captureException} from '@sentry/react';
import {getClientConfig, getRedirect} from "../../../actions/hydra";
import Preloader from "../../Preloader";
import {NoOfferMessage, PremiumOnlyOfferMessage} from "../../OffersScreen";
import Error from "../../Errors/Error";
import {clientId} from "../../../const/const";
import '../../../styles/consent.css'

const PremiumOnlyOffer = ({queryParams}) => {
  const id = queryParams?.[clientId];
  const [clientName, setClientName] = useState()
  const [clientLogo, setClientLogo] = useState()
  const [error, setError] = useState()
  const [onlyPremiumOffer, setOnlyPremiumOffer] = useState(false)
  const [premiumOffer, setPremiumOffer] = useState(false)
  const [noOffer, setNoOffer] = useState(false)

  /**
   * Load redirect_uri helper.
   *
   * @returns {Promise<void>}
   */
  async function performRedirect(forcePremium = false) {
    if (!window.localStorage) {
      const error = new Error("Local storage not supported")
      captureException(error)
      console.error(error)
    }
    if (queryParams) {
      const premium = queryParams?.premium ?? forcePremium;
      window.location.href = await getRedirect(id, premium);
    }
  }

  useEffect(() => {
    // Add class for setting background.
    document.body.classList.add('page-consent')
    if (id) {
      getClientConfig(id)
        .then(data => {
          if (!data?.config?.name || !data?.config?.logo) {
            setError("Missing oauth client configs");
            return false;
          }
          setClientName(data?.config?.name)
          setClientLogo(data?.config?.logo)
          const allowedDomains = data?.config?.metadata?.domains
          if (
            typeof allowedDomains === "object" &&
            allowedDomains.indexOf(queryParams?.domain) === -1 &&
            allowedDomains.indexOf(queryParams?.domain + '/') === -1
          ) {
            setError(`Brand Connections is not allowed on this domain: ${queryParams?.domain}`)
            return false;
          } else {
            if (data?.offers) {
              const exclusive = data?.offers.filter(item => item.exclusive)
              const community = data?.offers.filter(item => !item.exclusive)
              // Check if company has only premium offer.
              if (exclusive?.length !== 0 && community?.length === 0) {
                setOnlyPremiumOffer(true)
                setPremiumOffer(exclusive[0])
              }
              // Check if company has no offer for given user.
              else if (exclusive?.length === 0 && community?.length === 0) {
                setNoOffer(true)
              } else {
                performRedirect()
              }
            }
            // Check if company has no offer at all.
            else {
              setNoOffer(true)
            }
          }
        })
        .catch(error => {
          captureException(error)
          console.error(error)
        })
    }
    // Remove class for setting background.
    return () => document.body.classList.remove('page-consent')
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      {
        error
          ? <Error message={error}/>
          : onlyPremiumOffer
            ? <PremiumOnlyOfferMessage
              name={clientName}
              logo={clientLogo}
              offerTitle={premiumOffer?.title}
              getVetrewards={performRedirect}
            />
            : noOffer
              ? <NoOfferMessage
                name={clientName}
                logo={clientLogo}
                performRedirect={performRedirect}
              />
              : <Preloader title="Loading offers list ..."/>
      }
    </>
  );
};

export default PremiumOnlyOffer;