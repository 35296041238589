import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {login} from "../../../actions/hydra";
import {endPopupProcessing} from "../../../actions/popup";
import {clientId} from "../../../const/const";
import * as routes from "../../../const/routes";
import {CallbackScreen} from "../../OffersScreen";
import Preloader from "../../Preloader";

const Home = ({queryParams}) => {
  const id = queryParams?.[clientId]
  const navigate = useNavigate()
  const [userData, setUserData] = useState(false)

  /**
   * Redirect user to Auth App.
   */
  useEffect(() => {
    async function checkLoggedIn(id) {
      return await login(id)
    }

    if (id) {
      // Set corresponding startPage.
      if (queryParams?.startPage) {
        window.localStorage.setItem('brandConnectionsStartPage', decodeURI(queryParams.startPage))
      }
      checkLoggedIn(id)
        .then((userData) => {
          if (userData?.data?.payload) {
            setUserData(userData?.data?.payload)
          } else {
            navigate(routes.REDIRECT)
          }
        })
        .catch(error => {
          console.log("User is not logged in")
          navigate(routes.REDIRECT);
        })
    } else {
      throw new Error("Missing client_id parameter");
    }

    // eslint-disable-next-line
  }, [id]);

  const endProcessing = (error = null) => {
    endPopupProcessing(userData, error);
  }

  return (
    !userData
      ? <Preloader title="Redirecting to Authentication App ..."/>
      : <CallbackScreen
        userData={userData}
        endProcessing={endProcessing}
        clientId={id}
      />
  );
};

export default Home;
